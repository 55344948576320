import { rewards, tag, user as userDomain } from "@fscrypto/domain";
import { TopAnalyst } from "@fscrypto/domain/user";
import type { LoaderFunction, MetaFunction } from "@remix-run/node";
import { sortBy } from "lodash-es";
import { HomeContainer } from "~/features/home";
import type { MultiResourceTags } from "~/features/tags";
import { json, useLoaderData } from "~/remix";
import { DashboardSearchData } from "~/services/discover-service";
import { services } from "~/services/services.server";
import { getMetaTagProjects } from "~/utils/helpers";
import { redis } from "../../../connections/redis.server";

export const meta: MetaFunction<typeof loader> = (args) => {
  const projectList = getMetaTagProjects();
  const desc = `Thrive in Web3 with access to the best data. Including ${projectList} and many more.`;
  const ogDesc = `Free data for analysts. Build and grow with access to top protocol data, including ${projectList}, and many more.`;
  const ogTitle = "Dashboards | Flipside";
  const ogImage = "https://res.cloudinary.com/dsr37ut2z/image/upload/v1689870994/assets/backgrounds/flipside-bg.png";
  const url = args.data.baseUrl;

  return [
    { title: "Flipside: The Most Reliable Blockchain Data" },
    { name: "description", content: desc },
    { property: "og:description", content: ogDesc },
    { property: "og:title", content: ogTitle },
    { property: "og:type", content: "website" },
    { property: "og:url", content: url },
    { property: "og:image", content: ogImage },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:description", content: ogDesc },
    { name: "twitter:title", content: ogTitle },
    { name: "twitter:url", content: url },
    { name: "twitter:image", content: ogImage },
    { tagName: "link", rel: "canonical", href: url },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
  ];
};

type HomePagePayload = {
  userCount: number;
  dashboardCount: number;
  featuredProjects: tag.Tag[];
  topAnalysts: TopAnalyst[];
  trendingDashboardData: DashboardSearchData;
  insightsDashboardData: DashboardSearchData;
  rewardPrograms: rewards.Program[];
  userDashboardLikes: { userId: string; count: number }[];
  baseUrl: string;
  tags: MultiResourceTags;
};

export const loader: LoaderFunction = async ({ request }) => {
  const insightsDashboardsDataP = services.discover.getInsightsDashboards({ count: 3 });
  const trendingDashboardsDataP = services.discover.getTrendingDashboards({ count: 4 });
  const topAnalystsP = getTopUsersCache();
  const featuredP = getFeaturedProjectsCache();
  const getRewardPrograms = async () => {
    try {
      return await services.rewards.getActivePrograms();
    } catch (e) {
      // Lets not fail the page if the rewards service is down
      return [];
    }
  };

  const [featuredProjects, topAnalysts, trendingDashboardData, insightsDashboardData, rewardPrograms] =
    await Promise.all([featuredP, topAnalystsP, trendingDashboardsDataP, insightsDashboardsDataP, getRewardPrograms()]);

  const tags = await services.tags.findTagsByMultipleResourcesOfSameType(
    [...trendingDashboardData.items, ...insightsDashboardData.items],
    "dashboard",
  );

  return json({
    featuredProjects,
    tags,
    topAnalysts,
    trendingDashboardData,
    insightsDashboardData,
    rewardPrograms,
  });
};

const getFeaturedProjectsCache = async (): Promise<tag.Tag[]> => {
  const key = "projects:featured";
  const result = await redis.get(key);
  if (result) {
    return JSON.parse(result) as tag.Tag[];
  } else {
    const projects = (await services.tags.findProjectTags()).filter((t) => t.featured);
    await redis.set(key, JSON.stringify(projects), "EX", 60 * 30); // 30 minutes
    return projects;
  }
};

//todo: this is just a cached version of the logic thats exists. I think this could be cleaned up a bit.
const getTopUsersCache = async (): Promise<TopAnalyst[]> => {
  const key = "index-users:top";
  const result = await redis.get(key);
  if (result) {
    const top = userDomain.topAnalystsSchema.parse(JSON.parse(result));
    return top;
  } else {
    const topUsers = await services.users.search({ pageSize: 10, sort: "rank", offset: 0 });
    const topAnalysts: TopAnalyst[] = await Promise.all(
      topUsers.users.map((u) =>
        services.likes.getLikedDashboardCountByUser(u.id).then((c) => ({ ...u, dashboardLikes: c })),
      ),
    );
    await redis.set(key, JSON.stringify(topAnalysts), "EX", 60 * 10); // 10 minutes
    return topAnalysts;
  }
};

const DiscoverRoute = () => {
  const { featuredProjects, trendingDashboardData, insightsDashboardData, tags, topAnalysts, rewardPrograms } =
    useLoaderData<typeof loader>() as HomePagePayload;
  const sortedFeaturedProjects = sortBy(featuredProjects, "sortOrder");
  return (
    <HomeContainer
      topAnalysts={topAnalysts}
      trendingDashboardData={trendingDashboardData}
      insightsDashboardData={insightsDashboardData}
      featuredProjects={sortedFeaturedProjects}
      rewardPrograms={rewardPrograms}
      tags={tags}
    />
  );
};

export default DiscoverRoute;
